import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'
import LeadSpot from '../../components/solutions/LeadSpot'
import Newsletter from '../../components/Newsletter'

import TyneBridge from "../../../content/images/solutions/fadne/tyne-bridge.jpg"
import HowItFits from "../../../content/images/solutions/fadne/how-it-fits.jpg"

import CallToAction from '../../components/primary/CallToAction'

const FADNEPage = () => {
  const {
    markdownRemark
  } = useStaticQuery(graphql`
    query FADNEQuery {
      markdownRemark(frontmatter: { path: { eq: "/fadne" } }) {
        frontmatter {
          title
          description
          path
          lead {
            ...LeadSpotFragment
            ...LeadStreamerFragment
          }
          benefitsYou {
            blurbsTwo {
              title
              text
              image {
                modal: childImageSharp {
                  ...SharpImageModalFragment
                }
                thumb: childImageSharp {
                  fixed(width: 550, quality: 90) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          ...CallToActionFragment
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter

  return (
    <Page className="p-solutions p-solutions--manufacturers has-fingerPrints">
      <SEO title={pageData.title} description={pageData.description} />
      <Schema
        breadcrumbs={[
          {
            name: pageData.title,
            uri: pageData.path,
          },
        ]}
      />

      <section className="c-section c-section--pageHeader">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-pageHeader">
              <div className="row">
                <div className="col-12 col-lg-7">
                  <div>
                    <span className="c-pageHeader__title pt-lg-3 pt-xl-5">
                      Food & Drink North East
                    </span>
                  </div>
                  <h1 className="c-pageHeader__intro">
                    <strong>Erudus support FADNE</strong> in
                    <br />
                    backing the North East Food & Drink industry.
                  </h1>
                </div>
                <div className="col-12 col-lg-5">
                  <img
                    width="400px"
                    className="img-fluid"
                    src="/images/icons/careers/Lead-Spot.png"
                    srcSet="/images/icons/fadne/Lead-Spot.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="c-wave c-wave--bottom">
          <svg viewBox="0 0 500 150" preserveAspectRatio="none">
            <path
              style={{ stroke: 'none', fill: '#F4F4F4' }}
              d="M-5.92,115.95 C125.56,-215.63 292.04,320.22 500.84,21.20 L500.00,150.00 L0.00,150.00 Z"
            ></path>
          </svg>
        </div>
      </section>

      <section id="our-story" className="c-section c-section--whyErudus">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="row text-left">
              <div className="col-12 col-lg-6 col-xl-7">
                <h1 className="c-forLeadSteamer__header">
                  <br />
                  Erudus and FADNE have much in common - we’re both based in the heart of the North East, and share a common goal in helping and supporting food businesses in the area achieve their fullest potential. 
                  <br /> <br />
                </h1>   
                <p className="c-forLeadSteamer__text">
                  So nothing could be more natural than teaming up to achieve our collective vision. 
                  <br /> <br /> 
                  We’re proud of our region and what it has to offer the industry, and working together Erudus and FADNE believe we can show the world the true value of food and drink from the North East.
                </p>
              </div>
              <div className="c-forLeadSteamer__icon col-12 col-lg-6 col-xl-5 text-center"style={{ paddingTop: '55px' }}>
                <img
                  className="img"
                  width={pageData.lead.streamer.imageWidth}
                  src={`/images/icons/${pageData.lead.streamer.image}.png`}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

<section className="c-section c-section--forBenefits c-section--forBenefits--careers">
    {/* <div className="c-wave c-wave--top">
      <svg viewBox="0 0 500 150" preserveAspectRatio="none">
        <path
          style={{ stroke: 'none', fill: '#fff' }}
          d="M-0.84,110.03 C259.87,-110.03 333.80,252.13 500.84,76.47 L500.00,0.00 L0.00,0.00 Z"
        ></path>
      </svg>
    </div>

    <div className="c-section__outer container">
      {pageData.lead.streamer.tag && (
        <div id={pageData.lead.streamer.id} className="c-section__tag rounded-top">
          {pageData.lead.streamer.tag}
        </div>
      )}
      <div className="c-section__inner">
        <div className="c-forLeadSteamer row">
          <div className="col-12 col-lg-6 col-xl-7">
          {pageData.lead.streamer.text && (
              <p
                className="c-forLeadSteamer__text"
                dangerouslySetInnerHTML={{ __html: pageData.lead.streamer.text }}
              />
            )}
            <h1 className="c-forLeadSteamer__header">
                <br />
                Erudus and FADNE have much in common - we’re both based in the heart of the North East, and share a common goal in helping and supporting food businesses in the area achieve their fullest potential. 
                <br /> <br />
            </h1>   
            <p className="c-forLeadSteamer__text">
                So nothing could be more natural than teaming up to achieve our collective vision. 
                <br /> <br /> 
                We’re proud of our region and what it has to offer the industry, and working together Erudus and FADNE believe we can show the world the true value of food and drink from the North East.
            </p>
          </div>

          <div 
          className="c-forLeadSteamer__icon col-12 col-lg-6 col-xl-5 text-center"
          style={{ paddingTop: '55px' }}
          >
            <img
              className="img"
              width={pageData.lead.streamer.imageWidth}
              src={`/images/icons/${pageData.lead.streamer.image}.png`}
            />
          </div>
        </div>
      </div>
    </div> */}

     <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-forBenefits">
                <div
                  key="Who are FADNE?"
                  className='c-forBenefitsCard c-forBenefitsCard--reversed'
                >
                  <div className="c-forBenefitsCard__block c-forBenefitsCard__block--text">
                    <h5 className="c-forBenefitsCard__header">Who are FADNE?</h5>
                    <div className="c-forBenefitsCard__text">
                      <p>
                        FADNE stands for <a href='https://fadne.org/'>Food and Drink North East</a>, 
                        a company with the mission of guiding local food and drink companies of all sizes to their maximum profitability. 
                        <br /> 
                        <br /> 
                        A community-based initiative, FADNE champions positive trade and promotes a sustainable circular economy. 
                        They are the collective and inclusive voice for the region’s food and drink sector.
                      </p>
                    </div>
                  </div>

                  <div className="c-forBenefitsCard__block c-forBenefitsCard__block--image" >       
                        <img
                          src={TyneBridge}
                          alt="Tyne Bridge"
                          title="tyne-bridge"
                          width="550"
                          height="440"
                          loading="lazy"
                          className="c-forBenefitsCard__image img-fluid"
                        />            
                  </div>
                </div>
                <div
                  key="How it fits"
                  className='c-forBenefitsCard'
                >
                  <div className="c-forBenefitsCard__block c-forBenefitsCard__block--text">
                    <h5 className="c-forBenefitsCard__header">How Erudus fits in with your FADNE membership</h5>
                    <div className="c-forBenefitsCard__text">
                      <p>
                        When you sign up to be a FADNE Associate or Partner your membership will include free access to the Erudus platform, and access to our suite of 
                        <a href='https://erudus.com/manufacturers'> Manufacturer solutions</a> - as well as our expertise and industry knowledge. 
                        Some of the benefits you can enjoy with Erudus:
                        <br/>
                        <br/>
                        <ul>
                          <li>Save time and money by sharing and storing all of your product data accurately in one easy to access place.</li>
                          <li>Reduce daily requests for up-to-date product information from your customers costs.</li>
                          <li>Use the Erudus Manufacturer API to link with your existing in-house software.</li>
                          <li>Download single product specifications or a whole range of data as PDF or CSV files to view on screen or inprint.</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div className="c-forBenefitsCard__block c-forBenefitsCard__block--image"
                      style={{ display: 'block', marginTop: '50px' }}
                  >
                        <ul style={{ textAlign: 'left' }}>
                          <li>Use our powerful Query Builder to gain complex insight into any product in seconds.</li>
                          <li>Highlight your accreditations and certifications with your customers.</li>
                          <li>Unlock valuable Marketing potential for your products by using Query Builder.</li>
                          <li>Showcase your products in Image Hub and give Wholesalers instant access to a range of print and web quality product shots and assets.</li>
                        </ul>    
                        <img
                          src={HowItFits}
                          alt="How it fits"
                          title="how-it-fits"
                          width="550"
                          height="440"
                          loading="lazy"
                          className="c-forBenefitsCard__image img-fluid"
                        />        
                  </div>
                </div>
            </div>
          </div>
        </div>

  </section>

      <CallToAction ctaData={pageData.signUpCta} fill={`#f4f4f4`} />

      <Newsletter />
    </Page>
  )
}

export default FADNEPage